<script setup>
import Hyperimage from './Hyperimage.vue';
import Hyperlist from './Hyperlist.vue';
import { computed } from 'vue';
import { useStore } from "../store";
const store = useStore();
const props = defineProps(["id"]);
//TODO: this cannot be a constant, because it needs to change (image or list, is set in content editor.
const selector = 'image';

const data = computed(() => {
  return store.getFinalData(props.id, "fshyperarticles");
});

</script>

<template>
  <div v-if="data" class="wrapper">
    <h1 class="article-h1">{{ data.title }}</h1>
    <p class="article-body">{{ data.description }}</p>
    <Hyperlist v-if="selector === 'list'" :id="props.id"></Hyperlist>
    <Hyperimage v-else :id="props.id"></Hyperimage>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  padding: 4vw;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
}
</style>
