<script setup>
import { ref, onMounted, computed } from "vue";
import { RouterView, useRoute } from "vue-router";
import router from "./router";
import { useStore } from "./store";
import { getIconUrl } from "./shared/theme";
import { getConfig } from "./shared/theme";
import LandscapeOverlay from "./components/LandscapeOverlay.vue";

import Header from "./components/Header.vue";
import Overlay from "./components/Overlay.vue";

const themekey = import.meta.env.VITE_MG_THEME
  ? import.meta.env.VITE_MG_THEME
  : "feuerschiff";
import(`./assets/theme/${themekey}/theme.css`);

const route = useRoute();

window.document.title = getConfig("apptitle");

var store = useStore();
store.themekey = themekey;

if (store.dataDE == null && store.meta == null && store.themekey !== null) {
  console.log("load app data");
  store.load();
}

var showOverlay = ref(true);
var showBackButton = ref(false);

onMounted(() => {
  const pageVisitedKey = "page_visited";
  if (!localStorage.getItem(pageVisitedKey)) {
    console.log("first time user");
    showOverlay.value = true;
    store.firsTimeUser = true;
    localStorage.setItem(pageVisitedKey, "true");
  } else {
    console.log("user was already here");
    showOverlay.value = false;
    store.firsTimeUser = false;
  }

  //checkCameraPermission();
});

function toggleLanguage() {
  if (store.language == "de") {
    store.language = "en";
  } else {
    store.language = "de";
  }
}

//   function onBackClicked() {
//     console.log(window.history.state.back);
//     if(window.history.state.back !== '/' && window.history.state.back !== null){
//       router.back();
//       showBackButton.value = true;
//     } else {
//       router.push({ path: "/", replace: true });
//       showBackButton.value = false;
//     }
//   }

// function toggleBackButton() {
//   showBackButton.value = !showBackButton.value;
// }

function cameraPermissionDenied() {
  console.log("app camera permission denied");
}

function toggleOverlay() {
  showOverlay.value = !showOverlay.value;
}

const overlayObj = computed(() => {
  let obj = {
    headline: "",
    text: "",
  };

  if (store.meta != undefined) {
    if (route.params.id == undefined) {
      (obj.headline =
        store.meta.ui_texts.overlay_content.info_home.headline[store.language]),
        (obj.text =
          store.meta.ui_texts.overlay_content.info_home.text[store.language]);
    } else {
      (obj.headline =
        store.meta.ui_texts.overlay_content.info_exhibit.headline[
          store.language
        ]),
        (obj.text =
          store.meta.ui_texts.overlay_content.info_exhibit.text[
            store.language
          ]);
    }
  }
  return obj;
});

const appTitle = computed(() => {
  if (store.meta != undefined) {
    return store.meta.ui_texts.app_title[store.language];
  }
});

const showHeader = computed(() =>
  store.currentContentType === "embed" ? false : true
);

const headerButton = computed(() => {
  if (route.name === "home") {
    return getIconUrl("info");
  } else {
    return getIconUrl("back");
  }
});

function onButtonClicked() {
  console.log("click");
  if (route.name !== "home") {
    if (
      window.history.state.back !== "/" &&
      window.history.state.back !== null
    ) {
      router.back();
      showBackButton.value = true;
    } else {
      router.push({ path: "/", replace: true });
      showBackButton.value = false;
    }
  } else {
    toggleOverlay();
  }
}
</script>
<template>
  <div v-if="showHeader" class="mainHeaderWrapper">
    <Header
      @onLanguageClick="toggleLanguage"
      @clicked="onButtonClicked"
      :showBackButton="showBackButton"
      :appTitle="appTitle"
      :headerButton="headerButton"
    >
    </Header>
  </div>

  <LandscapeOverlay></LandscapeOverlay>

  <div class="mainContentWrapper">
    <Overlay
      :headline="overlayObj.headline"
      :text="overlayObj.text"
      @onLanguageClick="toggleLanguage"
      @toggleOverlay="toggleOverlay"
      :visible="showOverlay"
    >
    </Overlay>

    <RouterView @cameraPermissionDenied="cameraPermissionDenied" />
  </div>
</template>

<style>

#app {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}
.mainHeaderWrapper {
  width: 100%;
  height: var(--header-height);
}

.mainContentWrapper {
  width: 100%;
  flex-grow: 1;
}

@media (orientation: landscape) {
  .mainContentWrapper {
    display: none;
  }
}
</style>
