<script setup>
import { useStore } from "../store";
const store  = useStore();
const props = defineProps(["data"]);

var playerOptions = {
  loop : props.data.loop ? props.data.loop : false,
  autoplay : props.data.autoplay ? props.data.autoplay : false,
  controls : props.data.controls ? props.data.controls : true,
  src : 'content/media/' + props.data.audiofile,
}

console.log(playerOptions);

</script>
<template>
  <audio
    controls
    controlsList="nodownload noplaybackrate"
    :src="playerOptions.src"
    :autoplay="playerOptions.autoplay"
    :loop="playerOptions.loop"
    :controls="playerOptions.controls"
    noplaybackrate
  ></audio>
</template>

<style scoped>
audio {
  width: 100%;
  margin: 0;
  margin-bottom: 2%;
  margin-top: 2%;
}
</style>
