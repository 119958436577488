import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ExhibitView from "../views/ExhibitView.vue";
import NoMatch from "../components/NoMatch.vue";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/exhibit/:id",
      name: "exhibit",
      component: ExhibitView,
    },
    {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
      name: "nomatch",
      component: NoMatch,
    },
  ],
});

export default router;
