<script setup>
import NumberPuncher from "../components/NumberPuncher.vue";
import { ref, computed } from "vue";
import router from "../router";
import { useStore } from "../store";
import ScannerComponent from "../components/ScannerComponent.vue";
import { getConfig } from "../shared/theme";
import { usePuncher, useScanner } from "../shared/theme";

const emit = defineEmits(["cameraPermissionDenied"]);

var store = useStore();
var startOverlayAnimation = ref(false);

const numberPuncherRef = ref(null);
const scannerRef = ref(null);

var gotRightQrCodeBefore = ref(false);

var hostName = ref();
hostName.value = getConfig('hostname');

function codeReceived(incoming, type) {
  var number = 0;
  var match = false;
  if(gotRightQrCodeBefore.value == false){
    if (type == "scanner") {
      var s = incoming.split("/");
      console.log(s);
    
      if (incoming.includes(hostName.value)) {
        number = s[s.length - 1];
        for (let i = 0; i < store.meta.index.length; i++) {
          if (store.meta.index[i].targetnumber == number) {
            match = true;
            gotRightQrCodeBefore.value = true;
          }
        }
      } else {
        scannerRef.value.shake();
      }
    } else {
      if (incoming.length == 4) {
        number = incoming.slice(incoming.length - 4);
        console.log(number);
        for (let i = 0; i < store.meta.index.length; i++) {
          if (store.meta.index[i].targetnumber == number) {
            match = true;
            gotRightQrCodeBefore.value = true;
          }
        }

        if (match == false) {
          numberPuncherRef.value.shake();
        }
      }
    }
  }

  if (match && type == "scanner") {
    startOverlayAnimation.value = true;
    setTimeout(() => {
      router.push({ path: "/exhibit/" + number });
    }, 500);
  } else if (match) {
    router.push({ path: "/exhibit/" + number });
  }
}

var puncherText = computed(() => {
  let text = "";
  if (store.meta != undefined) {
    text = store.meta.ui_texts.home_view.number_field.info_text[store.language];
  }
  return text;
});

var scannerTexts = computed(() => {
  let obj = {};
  if (store.meta != undefined) {
    obj.noPermissionText =
      store.meta.ui_texts.home_view.qr_scanner.texts.no_permission[
        store.language
      ];
    obj.promptText =
      store.meta.ui_texts.home_view.qr_scanner.texts.info_permissions[
        store.language
      ];
    obj.grantedText =
      store.meta.ui_texts.home_view.qr_scanner.texts.info_howto[store.language];
  }
  return obj;
});
</script>

<template>
  <div class="wrapper">
    <ScannerComponent
      v-if="useScanner()"
      @codeReceived="codeReceived"
      :startOverlayAnimation="startOverlayAnimation"
      :texts="scannerTexts"
      ref="scannerRef"
    >
    </ScannerComponent>

    <div class="puncher">
      <NumberPuncher
        v-if="usePuncher()"
        :text="puncherText"
        ref="numberPuncherRef"
        @resetAnimationRef="resetAnimationRef"
        @codeReceived="codeReceived"
      ></NumberPuncher>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.permissionDenied {
  order: 2;
  height: 20vh;
  display: none;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .scanner {
    width: 100%;
    height: 60%;
  }

  .puncher {
    width: 100%;
    height: 40%;
  }
}

.animation {
  animation-name: horizontal-shaking;
  animation-duration: 0.5s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
