<script setup>
import { getIconUrl } from '../shared/theme';
import { useStore } from "../store";
import { computed } from 'vue';
const store = useStore();


var uiTexts = computed(() => {
    let text = '';
    if (store.meta != undefined) {
        console.log(store.meta.ui_texts.home_view.no_match.text[store.language]);
        text = store.meta.ui_texts.home_view.no_match.text[store.language];
    }
    return text;
});
</script>

<template>
    <div class="contentWrapper">
        <div class="contentDiv">
            <img :src="getIconUrl('no_content')"></img>
            <h1>{{ uiTexts }}</h1>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.contentWrapper {
    width: 100%;
    height: 100%;

    .contentDiv {
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>