<script setup>
import { useStore } from "../store";
const store  = useStore();
const props = defineProps(['data']);
const imageUrl = 'content/media/' + props.data;


console.log();
</script>
<template>
    <img :src="imageUrl"></img>
</template>

<style scoped>
img {
    width: 100%;
    margin: 0;
    margin-top: 2vw;
    margin-bottom: 2vw;
}
</style>
