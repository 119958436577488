<script setup>
import { useStore } from "../store";
import { defineProps, computed, ref } from "vue";
import router from "../router";
import { useElementSize } from '@vueuse/core'
const props = defineProps(["id"]);
const store = useStore();

const start = ref(false);

const data = computed(() => {
  return store.getFinalData(props.id, "fshyperarticles");
});

function openArticle(id) {
  console.log("openArticle", id);
  router.push({ path: "/exhibit/" + id });
};

const el = ref(null)
const { width } = useElementSize(el)

let img = new Image();
img.src = 'content/media/'+ data.value.map_image;
img.onload = function () {
  start.value = true;
};

let factor = computed(() => {
  return img.width / width.value;
});
</script>

<template>
  <div v-if="start">
    <div class="imageContainer">
      <img ref="el" :src="'content/media/' + data.map_image"></img>
      <div @click="openArticle(item.article)" v-for="item in data.linked_articles_list" class="marker"
        :style="{ 'left': item.pos_x / factor + 'px', 'top': item.pos_y / factor + 'px' }">
        <div class="outer"></div>
        <div class="inner"></div>
        <!-- <div :class="item.pos_x > img.width / 2 ? 'text textRight' : 'text textLeft'"> -->
        <div :class="item.pos_x < img.width / 3 ? 'text textLeft' : item.pos_x > img.width / 3 && item.pos_x < img.width * (2/3) ? 'text textCenter' : 'text textRight'">
          <p>{{ item.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.imageContainer {
  position: relative;
  overflow: hidden;
  .marker {
    position: absolute;
    display: flex;
    justify-content: center;

    .inner {
      height: 3vw;
      width: 3vw;
      background-color: var(--color-base);
      position: absolute;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    .outer {
      height: 6vw;
      position: absolute;
      width: 6vw;
      transform: translateY(-50%);
      background-color: red;
      border-radius: 50%;
      opacity: .5;
      animation-name: blink;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    .text {
      white-space: nowrap;
      background-color: var(--color-base);
      box-sizing: border-box;

      border-radius: 1vw;
      position: absolute;
      padding: 1.5vw;
      padding-top: 0vw;
      padding-bottom: 0vw;
      filter: drop-shadow(0px 0px 0.5vw #0000005e);
      transform: translateY(-50%);

      p {
        color: white;
        margin: 0;
      }
    }

    .textLeft {
      @extend .text;
      left: 4vw;
    }

    .textCenter {
      @extend .text; 
      top: 7vw;
    }

    .textRight {
      @extend .text;
      right: 4vw;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 0.1;
    }

    100% {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
  }
}
</style>
