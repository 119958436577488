const themekey = import.meta.env.VITE_MG_THEME
  ? import.meta.env.VITE_MG_THEME
  : "feuerschiff";

let config = await import(`../assets/theme/${themekey}/config.json`);

function getThemePath() {
  return `/assets/theme/${themekey}/`;
}

function getIconUrl(iconName) {
  const ret = new URL(
    `../assets/theme/${themekey}/icons/${iconName}.svg`,
    import.meta.url
  );
  return ret;
}

function getImageUrl(imageName) {
  const ret = new URL(
    `../assets/theme/${themekey}/images/${imageName}.jpg`,
    import.meta.url
  );
  return ret;
}

function getConfig(key) {
  return config[key];
}

function useScanner() {
  if (config.homeview_components.includes("QrScanner")) {
    return true;
  } else {
    return false;
  }
}

function usePuncher() {
  if (config.homeview_components.includes("NumberPuncher")) {
    return true;
  } else {
    return false;
  }
}

export {
  getThemePath,
  getIconUrl,
  getImageUrl,
  getConfig,
  useScanner,
  usePuncher,
};
