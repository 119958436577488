<script setup>
import { computed, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "../store";
import router from "../router/index.js";

import Article from "../components/Article.vue";
import Embed from "../components/Embed.vue";
import NoMatch from "../components/NoMatch.vue";
import Hyperarticle from "../components/Hyperarticle.vue";

var store = useStore();

const emit = defineEmits(["call-showBackButton"]);
const route = useRoute();
const routeLength = ref(0);
const currentRouteLength = ref(0);
//TODO – what does this do??
emit("call-showBackButton");
routeLength.value = window.history.length;

function goHome() {
  // console.log("nav back - remember ", routeLength.value, window.history.length)
  //let stepsBack = routeLength.value - window.history.length - 1;
  //router.go(stepsBack);
  //router.back();
  router.push("/", { replace: true });
}
defineOptions({
  inheritAttrs: false,
});

onUnmounted(() => {
  store.currentContentType = undefined;
});

var contentObject = computed(() => {
  var obj = { mediaType: undefined };
  if (store.meta !== null) {
    let matchobj = store.meta.index.find(
      (item) => item.targetnumber == route.params.id
    );

    if (matchobj) {
      obj = matchobj;
      console.log(obj);
      if (obj.linked_article) {
        obj.mediaType = "article";
      } else if (obj.linked_embed) {
        obj.mediaType = "embed";
      } else if (obj.linked_hyperarticle) {
        obj.mediaType = "hyperarticle";
      } else {
        obj.mediaType = undefined;
      }
      store.currentContentType = obj.mediaType;
    } else {
      const matchingArticle = store.getFinalData(route.params.id, "fsarticles");
      matchingArticle
        ? (obj = { mediaType: "article", linked_article: matchingArticle.id })
        : null;
    }
  }

  return obj;
});
</script>

<template>
  <div class="wrapper">
    <NoMatch v-if="contentObject.mediaType === undefined"></NoMatch>
    <Article
      v-if="contentObject.mediaType === 'article'"
      :id="contentObject.linked_article"
    ></Article>
    <Embed
      v-if="contentObject.mediaType === 'embed'"
      :id="contentObject.linked_embed"
      @exit="goHome"
    ></Embed>
    <Hyperarticle
      v-if="contentObject.mediaType === 'hyperarticle'"
      :id="contentObject.linked_hyperarticle"
    ></Hyperarticle>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background-color: white;
}
</style>
