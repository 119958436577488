<script setup>
import { getIconUrl } from "../shared/theme";
import { useStore } from "../store";
import { ref, computed } from "vue";
var store = useStore();

var text = computed(() => {
  let text = "";
  if (store.meta != undefined) {
    text = store.meta.ui_texts.overlay_content.info_landscape.text[store.language];
  }
  return text;
});

var headline = computed(() => {
  let headline = "";
  if (store.meta != undefined) {
    headline = store.meta.ui_texts.overlay_content.info_landscape.headline[store.language];
  }
  return headline;
});
</script>

<template>
  <div class="container">
    <div class="content">
        <h1 class="overlay-h1">{{headline}}</h1>
        <p class="overlay-body">{{text}}</p>
        <img class="animation" :src="getIconUrl('rotate')"></img>
        <img>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 99999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    padding: var(--border-padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
            width: 60%;
            text-align: center;
        }
        img {
            width: 15%;
        }
        .animation {
            animation-name: rotate;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
        @keyframes rotate {
            0% { transform: rotate(0deg)}
            45% { transform: rotate(90deg)}
            55% { transform: rotate(90deg)}
            100% { transform: rotate(0deg)}
        }
    }
}

@media (orientation: landscape) {
  .container {
    opacity: 1;
    pointer-events: inherit;
  }
}

</style>
