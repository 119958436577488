import { defineStore } from "pinia";
import axios from "axios";
export const useStore = defineStore("main", {
  
  state: () => ({
    meta: null,
    languages: {},
    dataByLanguage: {},
    language: undefined,
    firsTimeUser: true,
    cameraPermission: undefined,
    uiTexts: undefined,
    themekey : null,
    currentContentType: undefined
  }),
  actions: {
    async load() {
      console.log("content/meta.json");
      await axios
        .get("content/meta.json")
        .then((response) => {
          this.languages = response.data.languages;
          this.language = response.data.languages[0];
          this.meta = response.data;
        })
        .catch((error) => {
          console.log("error loading ", error);
        });

      for (let j = 0; j < this.languages.length; j++) {
        this.dataByLanguage[this.languages[j]] = {};
        for (let i = 0; i < this.meta.content_types.length; i++) {
          await axios
            .get("content/json/" + this.languages[j] + "/" + this.meta.content_types[i] + ".json")
            .then((response) => {
              this.dataByLanguage[this.languages[j]][this.meta.content_types[i]] = response.data;
            })
            .catch((error) => {
              console.log("error loading content ", error);
            });
        }
      }
    },
  },

  getters: {
    getFinalData(state) {
      return (id, type) => {
        if (state.dataByLanguage[state.language] && state.language && id && state.dataByLanguage[state.language][type]) {
          return this.dataByLanguage[this.language][type][id];
        }
      };
    },
    getImageName(state){
      return(()=>{
        let nane = '';
        
        return name; 
      });
    }
  },
});
