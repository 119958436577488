<script setup>
import { useStore } from "../store";
import { getImageUrl } from "../shared/theme";
const store = useStore();
const props = defineProps(["data"]);

var defaultOptions = {
  loop: false,
  mute: false,
  autoplay: false,
  controls: true,
  videofile: undefined,
  posterimage: getImageUrl('defaultPoster')
}

var playerOptions = Object.assign(defaultOptions, props.data);
console.log('playerOptions', playerOptions);

</script>
<template>
  <video preload="metadata" :autoplay="playerOptions.autoplay" :poster="'content/media/' + playerOptions.posterimage" :muted="playerOptions.mute" :loop="playerOptions.loop" :controls="playerOptions.controls" controlslist="nodownload noremoteplayback noplaybackrate foobar" :src="'content/media/' + playerOptions.videofile"></video>
</template>

<style scoped>
video {
  width: 100%;
  opacity: .75;
}
</style>
