<script setup>
const emit = defineEmits(["codeReceived", "resetAnimationRef"]);
import { getIconUrl } from "../shared/theme";
import VuePincodeOtp from 'vue3-pincode-otp';
import { reactive, ref, defineExpose, computed } from "vue";
import { getConfig } from "../shared/theme";
const props = defineProps(["startAnimation", "text"]);
var startAnimation = ref(false);

var numberOfDigits = ref();
numberOfDigits.value = getConfig('numberpuncher_digits')
console.log()

const pincode = reactive({
  value: "",
});

function shake() {
  startAnimation.value = true;
}

addEventListener("animationend", (event) => {
  startAnimation.value = false;
});

defineExpose({ shake });
</script>

<template>
  <div v-if="numberOfDigits !== undefined" class="wrapper">
    <div class="top">
      <p>{{ props.text }}</p>
    </div>
    <div class="inputWrapper" :class="{ animation: startAnimation }">
      <VuePincodeOtp v-model="pincode.value" :digits="numberOfDigits" />
      <button
        class="button"
        @click="emit('codeReceived', pincode.value, 'puncher')"
        :class="{ active: pincode.value.length === numberOfDigits }"
      >
        <img :src="getIconUrl('search')" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--border-padding);

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
  }

  .inputWrapper {
    display: flex;

    .button {
      width: 10vw !important;
      height: 10vw !important;
      margin-left: 1.5vw;

      opacity: 0.5;
      background-color: var(--color-base);
      &.active {
        opacity: 1;
      }
      img {
        width: 6%;
        position: absolute;
      }
    }

    @media (max-width: 700px) {
      .button {
        height: 13vw !important;
        width: 13vw !important;
        margin-left: 3vw;
        border-radius: var(--style-radius);
      }
    }

    div.vue-pincode-input-wrapper {
      *,
      input.vue-pincode-input.default {
        width: 10vw !important;
        height: 10vw !important;
        border-radius: var(--style-radius);
      }

      @media (max-width: 700px) {
        *,
        input.vue-pincode-input.default {
          width: 13vw !important;
          height: 13vw !important;
        }
      }
    }
  }
}

.animation {
  animation-name: horizontal-shaking;
  animation-duration: 0.5s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
